import React from 'react'
import HrmNavbar from './HrmNavbar'
import './css/UseFullFormLinks.css'
import { HashLink as Link } from "react-router-hash-link";
export default function UseFullFormLinks() {
    return (
        <div>
            <HrmNavbar />
            <div className="container">
                <div className='UseFullFormLinks-mainClass'>
                    <Link className="UseFullFormLinks-form-row" to='https://docs.google.com/forms/d/e/1FAIpQLSf_18qPjXlS-gyKyZw1L9y8Gh5u1Hpsmc6JZE_LM2QSBxsmLg/viewform' target='_blank'>
                        <h2 className="UseFullFormLinks-form-title" >Reimbursement Form</h2>
                        <div className="UseFullFormLinks-icon-buttons">
                            {/* Edit Icon */}
                            <button className="UseFullFormLinks-icon-button" >
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="UseFullFormLinks-icon">
                                    <path d="M10 13a5 5 0 0 1 0-7l1-1a5 5 0 0 1 7 7l-1 1" />
                                    <path d="M14 11a5 5 0 0 1 0 7l-1 1a5 5 0 0 1-7-7l1-1" />
                                </svg>
                            </button>

                        </div>

                    </Link>
                    <Link className="UseFullFormLinks-form-row" to='https://docs.google.com/forms/d/e/1FAIpQLScMFrIEfvHCvK1GrJErRbFU2VHwK-OHLjn9VCIAPNI8V52wLw/viewform' target='_blank'>
                        <h2 className="UseFullFormLinks-form-title">Leave & Overtime Request Form</h2>
                        <div className="UseFullFormLinks-icon-buttons">
                            <button className="UseFullFormLinks-icon-button">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="UseFullFormLinks-icon">
                                    <path d="M10 13a5 5 0 0 1 0-7l1-1a5 5 0 0 1 7 7l-1 1" />
                                    <path d="M14 11a5 5 0 0 1 0 7l-1 1a5 5 0 0 1-7-7l1-1" />
                                </svg>
                            </button>

                        </div>
                    </Link>
                    <Link  className="UseFullFormLinks-form-row" to='https://drive.google.com/file/d/1rLkkdUcM-LR1up4OVgsZTJGUuWhosplZ/view' target='_blank'>
                        <h2 className="UseFullFormLinks-form-title">Holiday Calendar (current year)</h2>
                        <div className="UseFullFormLinks-icon-buttons">
                            {/* Edit Icon */}
                            <button className="UseFullFormLinks-icon-button" >
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="UseFullFormLinks-icon">
                                    <path d="M10 13a5 5 0 0 1 0-7l1-1a5 5 0 0 1 7 7l-1 1" />
                                    <path d="M14 11a5 5 0 0 1 0 7l-1 1a5 5 0 0 1-7-7l1-1" />
                                </svg>
                            </button>

                        </div>

                    </Link>
                </div>
            </div>
        </div>
    )
}
